<template>
  <b-overlay
  :show="loading"
  rounded="lg">
  <b-card title="Direct Sales">
    <b-row>
      <!-- Modal for showing Job Order Details-->
      <b-modal
        v-model="showJobModal"
        :title="detailModal.title"
        :id="detailModal.id"
        size="lg"
        ok-only
      >
        <b-row>
          <b-col cols="3">
            Job Order Number :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.orderNumber }}
          </b-col>
          <b-col cols="3">
            Drum Name :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.fromBatch }}
          </b-col>
          <b-col cols="3">
            Type :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.type }}
          </b-col>
          <b-col cols="3">
            Created At:
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.createdAt }}
          </b-col>
          <b-col cols="3">
            Updated At :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.updatedAt }}
          </b-col>
          <b-col cols="12" style="margin-top:10px">
            <b-table
              :items="detailModal.content.items"
              :fields="items_detail_fields"
              responsive="sm"
              hover
              head-variant="dark"
              outlined
              small
              stacked="md"
            >
              <template #cell(no)="row">
                {{ row.index + 1 }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100"></div>
        </template>
      </b-modal>

      <!-- Modal for showing Job Order Details When Clicking Approve-->
      <b-modal
        v-model="promptDetail"
        title="Mama Drum Confirmation"
        id="promptDetail"
        size="lg"
        ok-only
      >
        <b-row>
          <b-col cols="3">
            Job Order Number :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.orderNumber }}
          </b-col>
          <b-col cols="3">
            Type :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.type }}
          </b-col>
          <b-col cols="3">
            Created At:
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.createdAt }}
          </b-col>
          <b-col cols="3">
            Updated At :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.updatedAt }}
          </b-col>
          <b-col cols="12" style="margin-top:10px">
            <div class="scrollable-table">
            <b-table
              :items="detailModal.content.items"
              :fields="items_detail_fields"
              responsive="sm"
              hover
              head-variant="dark"
              outlined
              small
              stacked="md"
            >
              <template #cell(no)="row">
                {{ row.index + 1 }}
              </template>
            </b-table>
            </div>
            <b-row>
              <b-col cols="6">
                <b-button
                  variant="success"
                  size="sm"
                  block
                  class="float-right"
                  @click="
                    approve(dataApproval);
                    promptDetail = false;
                  "
                  >Accept Mama Drum</b-button
                >
              </b-col>
              <b-col cols="6">
                <b-button
                  variant="danger"
                  size="sm"
                  block
                  class="float-left"
                  @click="
                    reject(dataApproval);
                    promptDetail = false;
                  "
                  >Reject Mama Drum</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100"></div>
        </template>
      </b-modal>
      <!-- modal for idle time out -->
      <b-modal
        v-model="afkmodal"
        id="modal-prevent-closing"
        ref="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <template #modal-header="{}">
          <!-- Emulate built in modal header close button action -->
          <h5>Pause Job</h5>
        </template>

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Select a Reason:"
            label-for="reason-select"
            invalid-feedback="Please select a reason"
            :state="reasonState"
          >
            <b-form-select
              id="reason-select"
              v-model="pauseReasonDefault"
              :options="pauseReasonOptions"
              :state="reasonState"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Please select a your Pause Reason --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </form>
        <template #modal-footer="">
          <b-button :disabled="loading" @click="restartAfkModal">save</b-button>
        </template>
      </b-modal>

      <b-col cols="12">
        <b-row style="margin-bottom:15px;">
          <b-col cols="4" />
          <b-col cols="4" />
          <b-col cols="4">
            <div>
              <b-row>
                <span class="spin" v-if="showSpinner"></span>
              </b-row>
              <b-form-input
                v-model="search"
                @input="debounceSearch"
                type="search"
                placeholder="Type to Search"
                style="float: right;"
              />
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom:10px" class="show-on-mobile">
          <b-col>
            <b-form-checkbox
              v-model="stackedStatus"
              value="md"
              unchecked-value="false"
            >
              Stacked Table
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="scrollable-table">
              <b-table
                :filter="search"
                responsive
                small
                hover
                head-variant="dark"
                outlined
                :items="jobOrders"
                :fields="headers"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"

                :stacked="stackedStatus"
              >
                <template #cell(jobOrderID)="row">
                  {{ row.item.id }}
                </template>
                <template #cell(orderNumber)="row">
                  {{ row.item.orderNumber }}
                </template>
                <template #cell(item)="row">
                  {{ simplyfiedItems(row.item.item) }}
                </template>
                <template v-slot:cell(actions)="row">
                  <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                    <template #button-content>
                      <feather-icon size="1x" icon="MenuIcon" />
                    </template>
                    <b-dropdown-item 
                      v-if="
                      row.item.props.status == 35 ||
                      row.item.status == 'Finished'
                    ">
                      <b-button
                        variant="success"
                        class="mr-1"
                        :to="{
                          name: 'view-direct-sales',
                          params: { id: row.item.id },
                        }"
                        size="sm"
                      >
                        <feather-icon size="1x" icon="EyeIcon"></feather-icon>
                      </b-button>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="permission.edit && row.item.props.status == 25 && row.item.props.MDE != undefined">
                      <b-button
                        variant="success"
                        size="sm"
                        style="margin-right:10px"
                        @click="
                          approveDetail(row.item);
                          promptDetail = true;
                          dataApproval = row.item;
                        "
                        >Mama Drum Confirmation</b-button
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="pt-1">
            <b-form-group
              label="Data Per Page"
              label-for="per-page-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                @input="queryJobOrder()"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pt-1">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              @change="onPageChange"
              :total-rows="totalRows"
              first-number
              last-number
              class="float-right"
              aria-controls="user-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</b-overlay>
</template>

<script>
import {
  dateFormat,
  userAccess,
  userAccessManufacture,
  viewAccess,
} from "@/utils/utils.js";

import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      stackedStatus: "md",
      showSpinner: false,
      headers: [
        { key: "soNumber", label: "SO Number"},
        { key: "picklistNumber", label: "Picklist Number"},
        { key: "lineDescription", label: "Line Item Description"},
        { key: "quantity", label: "Quantity"},
        { key: "unit", label: "Unit"},
        { key: "fromBatch", label: "From Batch"},
        { key: "toBatch", label: "To Batch"},
        { key: "cuttingNumber", label: "Cutting Number"},
        { key: "cableLength", label: "Cable Length"},
        { key: "cableWeight", label: "Cable Weight"},
        { key: "remark", label: "Remark"},
        { key: "props.type", label: "Type", filterable: true, sortable: true },
        // { key: 'item', label: 'Items', filterable: true, sortable: true },
        {
          key: "props.status",
          label: "Status",
          filterable: true,
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      //sort direction list
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      workorderNumModal: false,
      workorderNumber: null,
      jobContext: null,
      search: null,
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      buttonState: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      promptOption: false,
      dataWarehouse: "",
      promptDetail: false,
      dataApproval: "",

      //for show jobdetails
      showJobModal: false,
      detailModal: {
        id: "detail-modal-bom",
        title: "",
        content: {
          // jobOrderNumber:'',
          name: "",
          type: "",
          createdAt: "",
          updatedAt: "",
        },
      },
      //Pause
      afkmodal: false,
      pauseReasonDefault: null,
      pauseReasonOptions: [],
      reasonState: null,
      pauseIntervalID: null,
      logoutIntervalID: null,
      IDLE_TIMEOUT: 0,
      idleSecondsCounter: 0,

      //for loading
      loading:false,
    };
  },
  mounted() {
    // this.$store.dispatch("order/fetchOrder").catch((err) => console.log(err));
    // this.$store.dispatch("bom/getItems");

    this.queryJobOrder();

    // this.$store.dispatch("picklist/getPicklist");
    // this.$store.dispatch("joborder/getJobOrder");
    // this.getRecipes();

    this.$store.dispatch("plant/getReasonList").then((res) => {
      var drumPickReason = this.$store.getters["plant/getReasonList"].filter(
        (x) => x.reasonName == "Pause Job"
      );
      var allreason = drumPickReason[0].reasonList;
      var activeReason = allreason.filter((x) => x.status === "Active")
      this.reasonListDrumPick = activeReason.map((x) => x.reason)
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageDirectSales")) {
      this.perPage = this.$session.get("perPageDirectSales");
    }
    if (this.$session.has("sortByDirectSales")) {
      this.sortBy = this.$session.get("sortByDirectSales");
    }
    if (this.$session.has("sortDescDirectSales")) {
      this.sortDesc = this.$session.get("sortDescDirectSales");
    }

    //idle time out modal mounted
    let user = this.$store.getters["auth/getActiveUser"]
      this.$store.dispatch("pause/getActivePauseJob", user).then((res)=> {
      /* 
      result "res.pause" will be true or false
      if database has document pausejob with current username and idleRun = true,
      modal will appeared
      */
      this.afkmodal = res.pause
      res.pause ? this.startLogoutInterval() : this.startPauseInterval()
    })
      this.$store.dispatch("pause/getPauseJobSetting").then((res) =>{
      this.IDLE_TIMEOUT = res[0].intervalTime * 60
    })
    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeydown = () => {
      this.idleSecondsCounter = 0;
    };
    document.onscroll = () => {
      this.idleSecondsCounter = 0;
    };
  },
  destroyed() {
    this.stopPauseInterval();
    this.stopLogoutInterval();
  },
  watch: {
    afkmodal(value) {
      if(value) {
        // get reason list of pause job
        this.$store.dispatch("plant/getReasonList").then((res) => {
          var PauseJobReason = res.filter((x) => x.reasonName == "Pause Job");
          var allreason = PauseJobReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.pauseReasonOptions = activeReason.map((x) => x.reason)
        });
      }
    },
    perPage(perPageNew) {
      this.$session.set("perPageDirectSales", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByDirectSales", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescDirectSales", sortDescNew);
    },
  },

  methods: {
    ...mapActions({
      getJobOrders: "joborder/getDirectSales",
      // approveJobOrder: "joborder/approveJobOrder",
      // getJobOrderItem: "joborder/getJobOrderItem",
      // submitJobOrder: "joborder/submitJobOrder",
      // getRecipes: "planning/getRecipes",
      // getSalesOrder: "order/getOrderSales",
    }),

    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
    },

    queryJobOrder(){
      this.getJobOrders({
        entry : this.perPage,
        page : this.currentPage,
      }).then((data) => {
        console.log("data", data)
        this.processMetadata(data.metadata)
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },

    onPageChange(page) {
      this.currentPage = page;
      this.queryJobOrder();
    },

    restartAfkModal() {
      this.loading = true
      if (!this.checkFormValidity()) {
        return;
      }
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      this.startPauseInterval()

      if (this.pauseReasonDefault != null) {
        var pauseData = {
          user: this.userInfo,
          pauseReason: this.pauseReasonDefault,
          pauseReference: "Cutter HMI List "
        };
        this.$store
          .dispatch("pause/savePauseJob", pauseData )
          .then((res) => {
            this.loading = false
            console.log("puase saved", res, pauseData);
            this.afkmodal = false;
            this.stopLogoutInterval();
            this.$bvToast.toast("PauseJob Updated", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.loading = false
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }
    },
    checkIdleTime() {
      this.idleSecondsCounter++;
      // console.log(this.IDLE_TIMEOUT,":",this.idleSecondsCounter);
      if (this.idleSecondsCounter === this.IDLE_TIMEOUT) {
        
        var pauseData = {
          user: this.userInfo,
          pauseTimestamp: new Date(),
          props: {
            idleRun: true,
          },
          pauseInterval: this.pauseSetting.intervalTime,
        };
          this.$store
            .dispatch("pause/addPauseJob", pauseData)
            .then((res) => {
              this.stopPauseInterval();
              this.startLogoutInterval()
              this.afkmodal = true;
              this.$bvToast.toast("PauseJob Added", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
      }
    },
    /* 
      when idle timeout modal showed, this function will started
      and running an interval to check if current time as same as
      auto logout time from pause setting, if it happend system will auto logout 
    */
    idleToLogout() {
      var TimeSet = this.pauseSetting.logoutSessionMorning.split(":");
      var TimeSet2 = this.pauseSetting.logoutSessionNight.split(":");
      console.log(new Date().getHours(), ':',TimeSet[0], ' ', new Date().getMinutes(),':' ,TimeSet[1]);
      if (
        (new Date().getHours() == TimeSet[0] &&
          new Date().getMinutes() == TimeSet[1]) ||
        (new Date().getHours() == TimeSet2[0] &&
          new Date().getMinutes() == TimeSet2[1])
      ) {
        this.stopLogoutInterval();
        var pauseData = {
          user: this.userInfo,
          pauseReason: "Idle auto Logout",
          pauseReference: "Cutter HMI List"
        };
        this.$store
        .dispatch("pause/savePauseJob", pauseData )
        .then((res) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          //logout function
          axios.post("/common/auth/logout").then(() => {        
            this.$session.clear()
            this.$session.destroy()
            window.location.reload()
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      }
    },
    startPauseInterval() {
      this.pauseIntervalID = window.setInterval(this.checkIdleTime, 1000);
      console.log("startPauseInterval init");
    },
    stopPauseInterval() {
      window.clearInterval(this.pauseIntervalID);
      console.log("stopPauseInterval init");
    },
    startLogoutInterval() {
      this.logoutIntervalID = window.setInterval(this.idleToLogout, 1000);
      console.log("startLogoutInterval init");
    },
    stopLogoutInterval() {
      window.clearInterval(this.logoutIntervalID);
      console.log("stopLogoutInterval init");
    },
    //Pause Functions - END
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.reasonState = valid;
      return valid;
    },
    detail(item, index, event) {
      this.$store
        .dispatch("joborder/getJobOrderId", { id: item.id })
        .then((res) => {
          var data = this.$store.getters["joborder/getJobOrderId"];
          this.detailModal.title = "Detail Job Order";
          (this.detailModal.id = data.id),
            (this.detailModal.content = {
              orderNumber: data.orderNumber,
              type: data.props.type,
              items: data.item.map((x) => {
                return {
                  name: x.pickList.fromBatch,
                  orderNumber: x.orderNumber,
                  quantity: x.quantity,
                };
              }),
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
            });
          this.showJobModal = true;
        });
    },
    approveDetail(item, index, event) {
      this.$store
        .dispatch("joborder/getJobOrderId", { id: item.id })
        .then((res) => {
          var data = this.$store.getters["joborder/getJobOrderId"];
          var newCreated = dateFormat(data.createdAt).split(",")
          var newUpdated = dateFormat(data.updatedAt).split(",")
          this.detailModal.title = "Detail Job Order";
          (this.detailModal.id = data.id), 
            (this.detailModal.content = {
              orderNumber: data.orderNumber,
              type: data.props.type,
              items: data.item.map((x) => {
                console.log(x)
                return {
                  orderNumber: x.pickList.orderNumber,
                  picklistNumber: x.pickList.picklistNo + "_" + x.pickList.picklistLine,
                  lineItemDescription: x.pickList.lineItem + ", " + x.pickList.lineDescription,
                  quantity: x.pickList.quantity,
                  unit: x.pickList.unit,
                  fromBatch: x.pickList.fromBatch,
                  toBatch: x.pickList.toBatch,
                  cuttingNumber: x.pickList.cuttingNumber,
                  cableLength: x.pickList.cableLengthInfo,
                  cableWeight: x.pickList.cableWeight,
                  remark: x.pickList.remark,
                };
              }),
              createdAt: newCreated[0] + newCreated[1].replace(/\./g, ':'),
              updatedAt: newUpdated[0] + newUpdated[1].replace(/\./g, ':'),
            });
        });
    },
    // add spinner while loading on searching data process
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },
    async approve(data) {
      // item to be objectID
      let itemOnlyId = data.item.map((el) => {
        console.log("el", el)
        return {
          orderNumber: el.orderNumber,
          quantity: el.quantity,
          pickList: el.pickList.id,
        };
      });

      let sendStatus = {
        ...data,
        status: "Approved",
        props: {
          ...data.props,
          type: "Direct Sale",
          status: 35,
        },
      };

      sendStatus.item = itemOnlyId;

      let payload = {
        sendStatus : sendStatus,
        timestamp: "MDE",
      }

      await this.$store
        .dispatch("joborder/approveDirectSale", {
          id: sendStatus.id,
          data: payload,
        })
        .then(() => {
          this.$bvToast.toast("Successfully change status to 'Approved'", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("Direct Sale Job ALready Processed, Please Refresh the Page", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return
        });
        this.$router.push({ name: "view-direct-sales", params: {id:sendStatus.id} });
    },

     reject(data) {
      this.$store
        .dispatch("joborder/rejectDirectSale", {
          id: data.id
        })
        .then((res) => {
          this.$bvToast.toast(res, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.queryJobOrder()
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },

    simplyfiedItems(items) {
      return items.reduce((prev, next) => {
        if (next === null || next === undefined) {
          return prev;
        }

        let humanName = next.detail || next.name;

        return [...prev, humanName];
      }, []);
    },
    selectedItem(id) {
      if (id) {
        return this.items.filter((el) => {
          return el.id === id;
        })[0];
      }
    },
  },
  computed: {
    userInfo() {
      const user = this.$store.getters["auth/getActiveUser"]
      return { username: user.username, email: user.email}
    },
    pauseSetting() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      return pauseSetting[0];
    },
    getPauseJob() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      this.IDLE_TIMEOUT = pauseSetting[0]?.intervalTime * 60;
      var user = this.$store.getters["auth/getActiveUser"];
      let pausejobs = this.$store.getters["pause/getPauseJobs"];
      var currpause = pausejobs.filter(
        (x) => x.user?.username == user.username || x.user?.email == user.email
      );
      var hasIdleTrue = currpause.filter((data) => data.props.idleRun == true);
      // console.log("hasIdleTrue",hasIdleTrue);
      // console.log("currpause",currpause);
      if (hasIdleTrue.length == 1) {
        this.idleSecondsCounter = this.IDLE_TIMEOUT - 1;
        return hasIdleTrue[0];
      } else {
        return currpause[0];
      }
    },
    getUser() {
      var user = this.$store.getters["auth/getActiveUser"];
      return { username: user.username, email: user.email };
    },
    salesStore() {
      return this.$store.getters["order/getOrderSales"];
    },

    items() {
      var item = this.$store.getters["bom/getComponents"];
      return item;
    },

    jobOrders() {
      let job = this.$store.getters["joborder/getDirectSales"]
        .map((el) => {
          var jobType = el.props.type
          if (el.props.type == "Direct Sale") {
            jobType = "Direct Sale - 直销"
          }

          var pickList = el.item.map(item => {
            // console.log("item", item)
            return {
              ...item.pickList
            }
          })

            return {
              ...el,
              pickListNo: pickList.join(", "),
              props:{
                ...el.props,
                type: jobType
              },
              soNumber: pickList[0].orderNumber,
              picklistNumber: pickList[0].picklistNo + "_" + pickList[0].picklistLine,
              lineDescription: pickList[0].lineItem + "," + pickList[0].lineDescription,
              quantity: pickList[0].quantity,
              unit: pickList[0].unit,
              fromBatch: pickList[0].fromBatch,
              toBatch: pickList[0].toBatch,
              cuttingNumber: pickList[0].cuttingNumber,
              cableLength: pickList[0].cableLength,
              cableWeight: pickList[0].cableWeight,
              remark: pickList[0].remark,
            };
        });
      return job;
    },
    permission() {
      return userAccess("direct sales");
    },
    rows() {
      return this.jobOrders.length;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.headers
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    items_detail_fields() {
      return [ 
        {key: "orderNumber", label: "SO Number"},
        {key: "picklistNumber", label: "Picklist Number"},
        {key: "lineItemDescription", label: "Line Item Description"},
        {key: "quantity", label: "Quantity"},
        {key: "unit", label: "Unit"},
        {key: "fromBatch", label: "From Batch"},
        {key: "toBatch", label: "To Batch"},
        {key: "cuttingNumber", label: "Cutting Number"},
        {key: "cableLength", label: "Cable Length"},
        {key: "cableWeight", label: "Cable Weight"},
        {key: "remark", label: "Remark"},
      ];
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.scrollable-table {
  overflow-x: auto;
  width: 100%;
}
</style>
